import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import queryString from "query-string"
import { AllArticlesPage, theme } from "@rily/components"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const GET_PREVIEW_ARTICLES = gql`
  query Articles($start: Int!, $category: JSON!) {
    articles(
      publicationState: PREVIEW
      limit: 12
      start: $start
      where: { category: $category }
    ) {
      title
      id
      subtitle
      header {
        id
        alternativeText
        url
      }
      author {
        authorId
        name
      }
      category
    }
  }
`

const Container = styled.div`
  background-color: ${theme.colors.floralWhite};
  padding: 77px 0;
  min-height: 100vh;
`

const ArticleListing = ({ location }) => {
  const [dataWithUrls, setDataWithUrls] = useState<any>([])
  const [params, setParams] = useState<any>({
    category: Object.keys(theme.categoryColors),
    page: 1,
  })

  const { loading, error, data } = useQuery(GET_PREVIEW_ARTICLES, {
    variables: {
      start: (+params.page - 1) * 12 || 0,
      category: params.category || Object.keys(theme.categoryColors),
    },
  })

  // getting new params object on url change
  useEffect(() => {
    if (location.search) {
      const newParams = queryString.parse(location.search)
      setParams(newParams)
    }
  }, [location])

  // mapping through data to add link urls
  useEffect(() => {
    if (data) {
      const newData = data.articles.map(article => {
        return {
          ...article,
          url: `${location.origin}/articles/preview?articleId=${article.id}`,
        }
      })
      setDataWithUrls(newData)
    }
  }, [data])

  const changePage = page => {
    if (params.category) {
      navigate(`?category=${params.category}&page=${page}`)
    } else {
      navigate(`?page=${page}`)
    }
  }

  const filter = category => {
    navigate(`?category=${category}`)
  }

  return (
    <Container>
      {data && (
        <AllArticlesPage
          data={dataWithUrls}
          numberOfPages={Math.ceil(data.articles.length / 12)}
          currentPage={params.page}
          onFilter={filter}
          onChangePage={changePage}
          initialCategory={params.category || ""}
        />
      )}
    </Container>
  )
}

export default ArticleListing
